body {
    color: gray;
    font-size: 20px;
    background-color: #0F4C75;
    /* background-image: url('../../components/photos/textured-background.jpg'); */
    /* background-repeat: no-repeat; */
    /* background-size: cover; */
  }

h1 {
    color: white;
    text-align: center;
}
a {
    color: rgb(255, 255, 255);
}
h3.head {
    color: white;
}

p {
    color: #BBE1FA;

}

h2 {
    text-align: center;
    color: white;
}
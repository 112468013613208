img.pic {
    float: right;
    height: 10%;
    width: 20%;
    box-shadow: 5px 5px black;
}

h3 {
    color: #BBE1FA;
}
h3.overview {
    text-align: center;
}

.page {
    margin: 50px;
}

.Quotes {
    text-align: left;
    margin-top: 75px;
}